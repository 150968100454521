<template>
  <div v-if="isLoading" class="skeleton h-full w-full bg-gray-300 animate-pulse"></div>
  <div v-else>
    <div
      class="video-card w-[300px] h-[210px] min-w-[268px] max-w-[400px] px-3 pb-3 pt-4 flex flex-col items-start gap-4 cursor-pointer bg-primary-50 shadow-lg rounded-lg transition-transform duration-200 ease-in-out relative hover:-translate-y-1 hover:bg-blue-100 border border-border-black-50"
      @click="navigateToVideoDetail"
    >
      <div class="flex flex-col gap-2">
        <div :class="['absolute top-0 left-0 w-full h-2', cardTypeClass,'rounded-t-lg']"></div>
        
        <div class="flex items-center mb-1">
          <FaIcon class="mx-1"  :icon="typeIcon" :class="iconColor" />
          <span class="text-sm font-medium text-gray-600">{{ videoTypeLabel }}</span>
        </div>

        <div>
          <h3 class="text-base font-bold text-gray-800 leading-6">{{ title }}</h3>
          <p class="text-xs text-gray-600 leading-4">{{ description }}</p>
        </div>
      </div>

      <div>
        <button
          class="absolute bottom-3 right-3 flex items-center justify-center w-12 h-12 rounded-full bg-[#bfdfff] text-blue-600 px-4 box-border"
          @click.stop="playVideo"
        >
          <!-- Play icon -->
          <FaIcon class="mx-0.5" icon="fas fa-play" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, PropType } from 'vue';
import { useRouter } from 'vue-router';
import type { HelpCenterVideo } from '@/interfaces/HelpCenter';

const router = useRouter();

const props = defineProps({
  video: {
    type: Object as PropType<HelpCenterVideo>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const title = computed(() => props.video.title);
const description = computed(() => props.video.description);

const cardTypeClass = computed(() => {
  switch (props.video.type) {
    case 'training':
      return 'bg-[#6270FC]'; 
    case 'tutorial':
      return 'bg-[#00AAED]';
    case 'events':
      return 'bg-black';
    default:
      return 'bg-gray-500';
  }
});

const iconColor = computed(() => {
  switch (props.video.type) {
    case 'training':
      return 'text-[#6270FC]'; 
    case 'tutorial':
      return 'text-[#00AAED]';
    case 'events':
      return 'text-black';
    default:
      return 'text-gray-500';
  }
});

const videoTypeLabel = computed(() => {
  switch (props.video.type) {
    case 'training':
      return 'TRAINING';
    case 'tutorial':
      return 'TUESDAY TUTORIALS';
    case 'events':
      return 'EVENTS';
    default:
      return 'VIDEO';
  }
});

const typeIcon = computed(() => {
  switch (props.video.type) {
    case 'training':
      return 'far fa-graduation-cap';
    case 'tutorial':
      return 'far fa-screen-users';
    case 'events':
      return 'far fa-presentation-screen';
    default:
      return 'far fa-file-video';
  }
})

function playVideo(event: Event) {
  event.stopPropagation(); 
  console.log('Play video:', props.video.url);
  // Review later to properly incorporate video playing 
}

function navigateToVideoDetail() {
  router.push({ name: 'VideoDetail', params: { videoUrl: props.video.url } });
}
</script>
