<template>
    <HelpCenterSearch />
    <BrowseVideos />
    <VideoCard 
       :video = "videoTest"
    />
    <VideoCard 
       :video = "videoTest2"
    />
    <VideoCard 
       :video = "videoTest3"
    />
</template>

<script lang="ts" setup>
import HelpCenterSearch from './HelpCenterSearch.vue'
import BrowseVideos from './BrowseVideos.vue'
import VideoCard from './VideoCard.vue';
import  {type HelpCenterVideo, Channel } from '@/interfaces/HelpCenter';

const videoTest: HelpCenterVideo = {
  channels: [Channel.Web],
  title: 'Adjusting Rule Weights: ',
  description: 'Short video Customize Rule Rate point values to match the risk tolerance for your business needs.',
  url: 'https://example.com/path/to/video.mp4',
  type: 'training',
};

const videoTest2: HelpCenterVideo = {
  channels: [Channel.Web],
  title: 'Adjusting Rule Weights: ',
  description: 'Short video Customize Rule Rate point values to match the risk tolerance for your business needs.',
  url: 'https://example.com/path/to/video.mp4',
  type: 'tutorial',
};

const videoTest3: HelpCenterVideo = {
  channels: [Channel.Web],
  title: 'Adjusting Rule Weights: ',
  description: 'Short video Customize Rule Rate point values to match the risk tolerance for your business needs.',
  url: 'https://example.com/path/to/video.mp4',
  type: 'events',
};
</script>