// {
//     "channels": [
//       "string"
//     ],
//     "createdAt": "2019-08-24T14:15:22Z",
//     "description": "string",
//     "features": [
//       "string"
//     ],
//     "id": "string",
//     "isDeleted": "2019-08-24T14:15:22Z",
//     "publishTime": "2019-08-24T14:15:22Z",
//     "title": "string",
//     "type": "string",
//     "updatedAt": "2019-08-24T14:15:22Z",
//     "url": "string"
//   }

export enum Channel {
    Web = 'WEB'
}

export interface HelpCenterVideo {
    channels: Array<Channel>
    url: string
    title: string
    description: string
    type: string
}

import type { BasePaginationApiParams, PageInfo } from './PageInfo'
import type { ServiceResponse } from './Table'

export interface Video {
    id: string
    title: string
    description: string
    url: string
    type: string
    channels: string[]
    features: string[]
    publishTime: string
    createdAt: string // date time
    updatedAt: string
    isDeleted: string | null // date time
}

export interface HelpCenterState {
    recentSearches: string[]
    helpCenterVideos: Video[]
    pageInfo: PageInfo | null
    isLoading: boolean
    error: string | null
}

export type VideosResponse = ServiceResponse<Video[]>

export interface GetHelpCenterApiParamsReq extends BasePaginationApiParams<HelpCenterOrderByOptions> {
    includeChannels?: HelpCenterChannel[]
    includeFeatures?: HelpCenterFeature[]
    includeTypes?: HelpCenterType[]
    search?: string
}
export type GetHelpCenterApiParamsKeys = 'includeChannels' | 'includeFeatures' | 'includeTypes' | 'search'

export interface PostHelpCenterApiParamsReq {
    channels: HelpCenterChannel[]
    description: string
    features: HelpCenterFeature[]
    publishTime: string // date time
    title: string
    type: string
    url: string
}

export interface PutHelpCenterApiParamsReq {
    channels: HelpCenterChannel[]
    description: string
    features: HelpCenterFeature[]
    publishTime: string // date time
    title: string
    type: HelpCenterType
    url: string
}

export interface PutHelpCenterApiParamsRespData {
    channels: HelpCenterChannel[]
    createdAt: string // date time
    description: string
    features: HelpCenterFeature[]
    id: string
    isDeleted: string // date time
    publishTime: string // date time
    title: string
    type: HelpCenterType
    updatedAt: string // date time
    url: string
}

export interface GetHelpCenterVideosRespData {
    channels?: string[]
    createdAt: string // date time
    description: string
    features?: string[]
    id: string
    isDeleted: string // date time
    publishTime: string // date time
    title: string
    updatedAt: string
    url: string
}

export enum HelpCenterFeature {
    Assets = 'assets',
    Campaign = 'campaign',
    Remediation = 'remediation',
    Reporting = 'reporting',
    Rules = 'rules',
    TrafficSources = 'trafficSources',
    UserManagement = 'userManagement',
    VendorPortal = 'vendorPortal',
    Other = 'other'
}

export enum HelpCenterChannel {
    Calls = 'calls',
    Documents = 'documents',
    Email = 'email',
    Messaging = 'messaging',
    Social = 'social',
    Web = 'web'
}

export enum HelpCenterType {
    Events = 'events',
    TuesdayTutorials = 'tuesdayTutorials',
    TrainingVideos = 'trainingVideos'
}
export enum HelpCenterVideoType {
    Events = 'event',
    MasterClass = 'masterClass',
    Training = 'training'
}

/**
 * Defines possible `orderBy` options for the Help Center API.
 */
// To Do: consider renaming to specify just for API, not for query params
export enum HelpCenterOrderByOptions {
    PublishTimeAsc = 'publishTime',
    PublishTimeDesc = '-publishTime',
    TitleAsc = 'title',
    TitleDesc = '-title',
    TypeAsc = 'type',
    TypeDesc = '-type'
}
